import React, { useState, useEffect } from 'react';

const Right = ({ profileHover }) => {
  const [animate, setAnimate] = useState(false);
  const [showExperience, setShowExperience] = useState(false);

  useEffect(() => {
    if (profileHover) {
      setAnimate(true);
      setShowExperience(true);
    } else {
      setAnimate(false);
      setShowExperience(false);
    }
  }, [profileHover]);

  return (
    <div id="right">
      <div className={`contact ${animate ? "slide-left" : ""}`}>
        <ul>
          <li>
            <a href="mailto:rohman13@gmail.com" target="_blank" rel="noreferrer">
              <i className="bi bi-envelope"></i>
              Email
            </a>
          </li>
          <li>
            <a href="http://www.linkedin.com/in/beno-rohman" target="_blank" rel="noreferrer">
              <i className="bi bi-linkedin"></i>
              LinkedIn
            </a>
          </li>
        </ul>
      </div>
      <div className={`experience ${animate ? "slide-right" : ""}`} style={{ display: "none" }}>
        <p style={{ fontSize: "2em" }}>Hi there, I'm Ben.</p>
        <p>Born in Pati, 1992, I'm a web developer who enjoys creating things that work seamlessly and look great.</p>
        <p>I've got experience with <strong>JavaScript</strong>, <strong>Python</strong>, <strong>PHP</strong>, and <strong>Java</strong>, along with frameworks and tools like <strong>React</strong>, <strong>Next.js</strong>, <strong>Laravel</strong>, <strong>Spring Boot</strong>, <strong>WordPress</strong>, <strong>PostgreSQL</strong>, <strong>Firebase Database</strong>, <strong>Microsoft Graph API</strong>, <strong>Google Cloud</strong>, and more. I also enjoy exploring AI and data analytics, finding patterns and turning them into actionable insights.</p>
        <p>Outside of work, you’ll find me exploring the world through books or spending quality time with my family. </p>
        <p>I believe in a less-is-more approach, keeping things straightforward and letting the work speak for itself.</p>
      </div>
    </div >
  );
};

export default Right;