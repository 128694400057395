import React from 'react';
import HomePage from './Home';
import './index.css';

const MainPage = () => {
  return (
    <div>
      <HomePage />
    </div>
  );
};

export default MainPage;