// pages/Home/index.js
import React, { useState } from 'react';
import Left from '../components/Left';
import Right from '../components/Right';

const HomePage = () => {
  const [profileHover, setProfileHover] = useState(false);
  return (
    <div className='main-container'>
      <div className="slasher" style={{ clipPath: 'polygon(0px 0px, 50% 0px, 50% 100%, 0px 100%)' }}></div>
      <div className="profile-container" >
        <div
          className="profile"
          onMouseEnter={() => setProfileHover(true)}
          onMouseLeave={() => setProfileHover(false)}
        >
          <img src="./assets/img/beno.jpg" alt="beno" />
        </div>
      </div>
      <Left />
      <Right
        profileHover={profileHover}
      />
    </div >
  );
}

export default HomePage;