import React from "react";
import 'bootstrap-icons/font/bootstrap-icons.css';

const Left = () => {
  return (
    <div id="left">
      <div className="welcome">Welcome to my space,</div>
      <div className="quote">where simplicity speaks.</div>
    </div>
  );
};

export default Left;